import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { dayFormat, monthFormat } from '../../helper/dateFormat'
import { getScaledImage } from '../../helper/utilities'

const myQuery = graphql`
  {
    allStrapiBlog(sort: { order: DESC, fields: publishedOn }) {
      nodes {
        id
        blogImg {
          url
        }
        title
        shortDesc
        publishedOn
        slug
        authors {
          name
          slug
          id
        }
        tags {
          slug
          title
          id
        }
      }
    }
  }
`

const BlogPageArea = () => {
  const {
    allStrapiBlog: { nodes: blogs },
  } = useStaticQuery(myQuery)

  return (
    <div className="blog-area pt-100 pb-70">
      <div className="container">
        <div className="section-title text-center">
          <span className="sp-color2">Our Blogs</span>
          <h2>Let’s Check What our Team is Writing</h2>
        </div>

        <div className="row pt-45">
          {blogs
            //.sort((a, b) => a.id - b.id)
            .map(blogcard => {
              const { authors, tags } = blogcard
              // console.log('authors', authors)
              return (
                <div className="col-lg-4 col-md-6" key={blogcard.id}>
                  <div className="blog-card">
                    <div className="blog-img">
                      <Link to={`/blogs/${blogcard.slug}`}>
                        <img
                          src={getScaledImage(blogcard.blogImg.url, 300)}
                          alt="Blog"
                          height="200px"
                        />
                      </Link>
                      <div className="blog-tag">
                        <h3>{dayFormat(blogcard.publishedOn)}</h3>
                        <span>{monthFormat(blogcard.publishedOn)}</span>
                      </div>
                    </div>
                    <div className="content">
                      <ul>
                        {authors &&
                          authors.map(author => {
                            return (
                              <li key={author.id}>
                                <Link to={`/blogs/${blogcard.slug}`}>
                                  <i className="bx bxs-user" />
                                  <span> {author.name} </span>
                                </Link>
                              </li>
                            )
                          })}
                        {tags &&
                          tags.map(tag => {
                            return (
                              <li key={tag.id}>
                                <i className="bx bx-purchase-tag-alt" />
                                <span> {tag.title} </span>
                              </li>
                            )
                          })}
                      </ul>
                      <h3>
                        <Link to={`/blogs/${blogcard.slug}`}>{blogcard.title}</Link>
                      </h3>
                      <p>{blogcard.shortDesc}</p>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default BlogPageArea
